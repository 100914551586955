import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Grid } from '@material-ui/core'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export default function HomeComment() {
    const { t } = useTranslation();
    return (
        <Grid container className='home-comment-container'>
            <Grid
                data-sal="slide-right"
                //data-sal="fade-in"
                data-sal-duration="2000"
                item xs={12} md={4}>
                <Grid container justify='center'>
                    <StaticImage
                        src={'../../images/avatar-comment.png'}
                        alt='avatar'
                        quality={100}
                        objectFit={'contain'}
                        className='avatar animated-image'
                        width={320}
                    />
                </Grid>
            </Grid>
            <Grid
                data-sal="slide-left"
                //data-sal="fade-in"
                data-sal-duration="2000"
                item xs={12} md={8}>
                <Grid container>
                    <div className='comment-container'>
                        <div className='border-container'>
                            <h1>{t('Home Comment 1')} <span>{t('Home Comment 2')}</span> {t('Home Comment 3')}</h1>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Grid >
    )
}
