import * as React from "react"  
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next';

import MainLayout from "../layouts/MainLayout"
import Seo from "../components/seo"
import HomeBanner from "../components/HomeBanner"
import HomeInfo from "../components/HomeInfo"
import HomeHowWork from "../components/HomeHowWork"
import HomeFeatures from "../components/HomeFeatures"
import HomeComment from "../components/HomeComment"
import HomeCheck from "../components/HomeCheck"
import HomeSoonArriving from "../components/HomeSoonArriving"
import Footer from './../components/layouts/Footer'
import TagManager from 'react-gtm-module'
import {Helmet} from "react-helmet";

import Chat from "../components/chatbot/index"

const tagManagerArgs = {
    gtmId: 'GTM-K7MWMNG',
    events: {
      sendUserInfo: 'gtm.init_consent'
    }
}

export default function IndexPage() {
  React.useEffect(()=>{
    TagManager.initialize(tagManagerArgs);
    const worker = new window.Worker('/worker.min.js');
    worker.onmessage = (e) => {
      console.log(e);
    };
    worker.onerror = (err) => err;
    console.log(worker);
    /*var el = document.createElement('script');
    el.src = '../../librarys/sdk.min.js';
    console.log("xd")
    console.log(el)
    el.async = 'true';
    console.log("xdd")
    el.addEventListener('load', function () {
      indigitall.init({
        appKey: '11d42cd1-7426-4505-8565-5240c6db395e',
        workerPath: '../../librarys/worker.min.js'
      });
    });
    document.head.appendChild(el);*/
   
    /*if(window){
      if(window.indigitall){
        window.indigitall.init({
          appKey:" 11d42cd1-7426-4505-8565-5240c6db395e",
          urlDeviceApi: 'https://eu2.device-api.indigitall.com/v1',
          workerPath: "/worker.min.js",
          requestLocation: true,
          onError: (err)=>{
            console.log("error")
            console.log(err);
          }
        });
      }
    }*/

  },[])
  
  const {t} = useTranslation();
  return (
    <MainLayout enableSticky={true}>
      <Seo title={t('Home')} />
      <Helmet>
        <script
          src="/sdk.min.js"
          onload="window.indigitall.init({
            appKey: 'c85a00fc-c759-42e0-81d6-206e3af28f53',
            urlDeviceApi: 'https://eu2.device-api.indigitall.com/v1',
            workerPath: '/worker.min.js',
            requestLocation: true,
            onInitialized: (i)=>{
              console.log('Inicializado!');
              console.log(i);
            },
            onError: (err)=>{
              console.log(err)
            }
          })"
          async>
        </script>
       
        <script 
          src="https://www.googletagmanager.com/gtag/js?id=G-EP7KC6DJWV"
          onload = "window.dataLayer = window.dataLayer || []; dataLayer.push('js', new Date()); dataLayer.push('config', 'G-EP7KC6DJWV');"
          async>
        </script>
      </Helmet>
      <HomeBanner/>
      <HomeInfo/>
      <HomeHowWork/>
      <HomeFeatures/>
      <HomeComment/>
      <HomeCheck/>
      <HomeSoonArriving/> 
      <Footer/>
    </MainLayout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

