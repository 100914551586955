import React from 'react' 
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Button, Container } from '@material-ui/core'
import SearchCabins from './../SearchCabins'

export default function HomeBanner() {

    const { t } = useTranslation();

    const { placeholderImage } = useStaticQuery(
        graphql`
          query {
            placeholderImage: file(relativePath: { eq: "background-banner.png" }) {
              childImageSharp {
                gatsbyImageData(quality:100,layout:FULL_WIDTH,transformOptions:{fit:COVER}, aspectRatio: 2.05 , placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        `
    )
    const image = getImage(placeholderImage)

    // Use like this:
    const bgImage = convertToBgImage(image)

    return (
        <BackgroundImage
            Tag="section"
            {...bgImage}
            data-sal="fade"
            data-sal-duration="2000"
        >
            <Container className='content-banner'>
                <h1><span>{t('Banner Message P1')}</span><br></br><span>{t('Banner Message P2')}</span><span>{t('Banner Message P3')}</span></h1>
                <br></br>
                <SearchCabins/>
                <br></br>
                <div>
                    <Button variant="outlined" color="primary" size="large">
                        {t('More Information')}
                    </Button>
                </div>
            </Container>
        </BackgroundImage>
    )
}
