import React from 'react' 
import { getImage,StaticImage} from 'gatsby-plugin-image'
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { Button, Container } from '@material-ui/core'
import IconHowWork1 from './../../images/icon-how-work-1.png'
import IconHowWork2 from './../../images/icon-how-work-2.png'

export default function HomeHowWork() {
    const { t } = useTranslation();

    const { placeholderImage } = useStaticQuery(
        graphql`
          query {
            placeholderImage: file(relativePath: { eq: "background-how-work.png" }) {
              childImageSharp {
                gatsbyImageData(quality:100,layout:FULL_WIDTH,transformOptions:{fit:COVER}, aspectRatio: 2.05 , placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        `
    )
    const image = getImage(placeholderImage)

    // Use like this:
    const bgImage = convertToBgImage(image)
    return (
        <BackgroundImage
            Tag="section"
            {...bgImage}
        >
            <Container className='content-how-work'>
                <h1
                data-sal="slide-right"
                data-sal-duration="2000"
                data-sal-delay="300"
                >{t('How Work Title')}</h1>
                <br></br>
                <div 
                data-sal="slide-right"
                data-sal-duration="2000"
                data-sal-delay="500"
                className='item-how-work'> 
                  <img src={IconHowWork1} alt='cloud icon'/>
                  <p>{t('How Work P1')}</p>
                </div> 
                <div 
                data-sal="slide-right"
                data-sal-duration="2000"
                data-sal-delay="700"
                className='item-how-work'> 
                  <img src={IconHowWork2} alt='clock icon'/>
                  <p>{t('How Work P2')}</p>
                </div>
                <br></br>
                <div 
                data-sal="slide-right"
                data-sal-duration="2000"
                data-sal-delay="900"
                >
                    <Button variant="contained" color="primary" size="large">
                        {t('More Information')}
                    </Button>
                </div>
            </Container>
        </BackgroundImage>
    )
}
