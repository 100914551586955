import React from 'react' 
import { Container, Grid } from '@material-ui/core'
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PointWhite from '../../images/point-white.png';
import LineWhite from '../../images/line-white.png';

export default function HomeCheck() {
    const { t } = useTranslation();

    return (
        <div className='check-container'>
            <Container>
                <Grid 
                data-sal="slide-right"
                data-sal-duration="2000"
                container alignContent='center'>
                    <Grid container item xs={12} md={6} justify='center' direction='column'>
                        <h1>{t('Check Title P1')}</h1>
                        <h2>{t('Check Title P2')}</h2>
                        <h3>{t('Check Title P3')}</h3>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <div className='check-items'>
                            <div className='check-item'>
                                <div><img src={PointWhite} /></div>
                                <p>{t('Check P1')}</p>
                            </div>
                            <div className='check-item-line'><img src={LineWhite} /></div>
                            <div className='check-item'>
                                <div><img src={PointWhite} /></div>
                                <p>{t('Check P2')}</p>
                            </div>
                        </div>
                        <div className='check-items'>
                            <div className='check-item'>
                                <div><img src={PointWhite} /></div>
                                <p>{t('Check P3')}</p>
                            </div>
                            <div className='check-item-line'><img src={LineWhite} /></div>
                            <div className='check-item'>
                                <div><img src={PointWhite} /></div>
                                <p>{t('Check P4')}</p>
                            </div>
                        </div>
                        <div className='check-items'>
                            <div className='check-item'>
                                <div><img src={PointWhite} /></div>
                                <p>{t('Check P5')}</p>
                            </div>
                            <div className='check-item-line'><img src={LineWhite} /></div>
                            <div className='check-item'>
                                <div><img src={PointWhite} /></div>
                                <p>{t('Check P6')}</p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
