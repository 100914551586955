import React from 'react' 
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Grid, Container } from '@material-ui/core'
import IconFeature1 from './../../images/icon-feature-1.png'
import IconFeature2 from './../../images/icon-feature-2.png'
import IconFeature3 from './../../images/icon-feature-3.png'
import IconFeature4 from './../../images/icon-feature-4.png'


export default function HomeFeatures() {
  const { t } = useTranslation();

  const { placeholderImage } = useStaticQuery(
    graphql`
          query {
            placeholderImage: file(relativePath: { eq: "background-features.png" }) {
              childImageSharp {
                gatsbyImageData(quality:100,layout:FULL_WIDTH,transformOptions:{fit:COVER}, aspectRatio: 2.05 , placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        `
  )
  const image = getImage(placeholderImage)

  // Use like this:
  const bgImage = convertToBgImage(image)
  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
    >
      <Container className='content-features'>
        <Grid container justify={'flex-end'} className='vh'>
          <Grid item md={6}>
            <Grid container alignContent={'center'} className='vh' spacing={3}>
              <Grid item xs={12}>
                <Grid container direction='column' className='content-features-title'>
                  <h1
                  data-sal="slide-left"
                  data-sal-duration="2000"
                  data-sal-delay="300"
                  >{t('Features Title P1')}</h1>
                  <h2
                  data-sal="slide-left"
                  data-sal-duration="2000"
                  data-sal-delay="500"
                  >{t('Features Title P2')}</h2>
                </Grid>
              </Grid>
              <Grid 
              data-sal="slide-left"
              data-sal-duration="2000"
              data-sal-delay="600"
              item xs={6} md={3}>
                <Grid container direction='column' alignItems='center'>
                  <div><img src={IconFeature1} alt='feature 1' /></div>
                  <p>{t('Features P1')}</p>
                </Grid>
              </Grid>
              <Grid 
              data-sal="slide-left"
              data-sal-duration="2000"
              data-sal-delay="700"
              item xs={6} md={3}>
                <Grid container direction='column' alignItems='center'>
                  <div><img src={IconFeature2} alt='feature 2' /></div>
                  <p>{t('Features P2')}</p>
                </Grid>
              </Grid>
              <Grid 
              data-sal="slide-left"
              data-sal-duration="2000"
              data-sal-delay="800"
              item xs={6} md={3}>
                <Grid container direction='column' alignItems='center'>
                  <div><img src={IconFeature3} alt='feature 3' /></div>
                  <p>{t('Features P3')}</p>
                </Grid>
              </Grid>
              <Grid 
              data-sal="slide-left"
              data-sal-duration="2000"
              data-sal-delay="900"
              item xs={6} md={3}>
                <Grid container direction='column' alignItems='center'>
                  <div><img src={IconFeature4} alt='feature 4' /></div>
                  <p>{t('Features P4')}</p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </BackgroundImage>
  )
}
