import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import AccessTime from '@material-ui/icons/AccessTime'

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// import './SearchCabins.scss'
import { getBases } from '../../data';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { getMomentNowDate } from '../../utils/datetime';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next'; 
import { getTimeAllowed } from '../../utils/functions'
import { es, enUS } from "date-fns/locale";
import { HOURS_IN_ADVANCE, MIN_HOURS_RESERVATION } from '../../contants/globals';
import { navigate } from 'gatsby-link';

const useStyles = makeStyles((theme) => ({
    formControl: {
        // marginTop: theme.spacing(2),
        // marginBottom: theme.spacing(3),
        width: '100%',
    },
    inputDate: {
        marginBottom: '-16px'
    }
}));

Yup.addMethod(Yup.string, "minTime", function (minTime, errorMessage) {
    return this.test(`test-min-time`, errorMessage, function (value) {
        const { path, createError } = this;
        const dateNow = moment().format('yyyy-MM-DD');
        const minDateTime = moment(`${dateNow} ${this.resolve(minTime)}`, 'yyyy-MM-DD HH:mm');
        const dateTime = moment(`${dateNow} ${value}`, 'yyyy-MM-DD HH:mm');
        return (
            dateTime.isSameOrAfter(minDateTime) ||
            createError({ path, message: errorMessage })
        );
    });
});

export default function SearchCabins() {
    const classes = useStyles();
    const [bases, setBases] = useState([]);
    const { t } = useTranslation();
    const { language } = useContext(I18nextContext);

    useEffect(() => {
        getBases()
            .then(data => {
                setBases(data);
            })
            .catch(console.log)
    }, [])

    const formik = useFormik({
        initialValues: {
            base: '',
            initDate: moment().add(HOURS_IN_ADVANCE, 'hours').toDate(),
            initTime: '',
            endDate: moment().add(HOURS_IN_ADVANCE, 'hours').toDate(),
            endTime: '',
            isDisabled: false,
        },
        validationSchema: Yup.object({
            base: Yup
                .string()
                .required(t('Validation Airport')),
            initDate: Yup
                .date()
                .min(getMomentNowDate(0), 'No se puede seleccionar una fecha anterior a la de hoy')
                .max(Yup.ref("endDate"), 'No se puede seleccionar una fecha mayor al del check out')
                .required('La fecha de check in es requerido'),
            initTime: Yup
                .string()
                .required('La hora de check in es requerido'),
            endDate: Yup
                .date()
                .min(getMomentNowDate(0, Yup.ref("initDate")), 'No se puede seleccionar una fecha anterior a la del check in')
                .required('La fecha de check out es requerido'),
            endTime: Yup
                .string()
                //.minTime(Yup.ref("initTime"), 'No se puede seleccionar una hora menor a la de check in')
                .required('La hora de check out es requerido'),
            isDisabled: Yup
                .boolean()
        }),
        onSubmit: async (formData) => {
            const data = { ...formData }; 
            navigate(language === 'en' ? `/${language}/reserve` : `/reserve`, { state: { data } });
        }

    })
    return (
        <div className='reserve-card'>
            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
                <Card className='card'>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2} className='input-container'>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <p>{t('Airport')}</p>
                                    </Grid>
                                </Grid>
                                <FormControl variant="standard" className={classes.formControl}>
                                    <InputLabel
                                        id="demo-simple-select-outlined-label"

                                    >{t('Airport')}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label={t('Airport')}
                                        onChange={(event, child) => formik.setFieldValue('base', event.target.value)}
                                        value={formik.values.base}
                                        error={formik.touched.base && formik.errors.base}
                                    >
                                        <MenuItem value="">
                                            <em>{t('None')}</em>
                                        </MenuItem>
                                        {
                                            bases.map((item, index) => (
                                                <MenuItem key={index} value={item.base_id}>{`${item.base_iata_code} ${item.base_name}`}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} className='input-container'>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <p>{t('Check in')}</p>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} md={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === 'es' ? es : enUS}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            className={classes.inputDate}
                                            id="date-picker-inline"
                                            label=""
                                            onChange={(name, value) => formik.setFieldValue('initDate', moment(value, 'DD/MM/yyyy').toDate())}
                                            value={formik.values.initDate}
                                            error={formik.touched.initDate && formik.errors.initDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            fullWidth
                                            minDate={moment().add(24, 'hours').toDate()}
                                            helperText=""
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} md={6} className='input-time'>
                                    <Grid container>
                                        <FormControl variant="standard" className={classes.formControl}>
                                            <InputLabel
                                                id="select-init-datetime"

                                            >{t('Hora')}
                                            </InputLabel>
                                            <Select
                                                labelId="select-init-datetime"
                                                id="demo-simple-select-outlined"
                                                onChange={(event, child) =>{
                                                    formik.setFieldValue('initTime', event.target.value);
                                                    if(getTimeAllowed(formik.values.endTime,HOURS_IN_ADVANCE).length==0){
                                                        formik.setFieldValue('endTime','');
                                                    }
                                                }}
                                                value={formik.values.initTime}
                                                error={formik.touched.initTime && formik.errors.initTime}
                                                label={t('Hora')}
                                                IconComponent={() => <AccessTime style={{ color: '#777777', marginRight: 12 }} />}
                                            >
                                                <MenuItem value="">
                                                    <em>{t('None')}</em>
                                                </MenuItem>
                                                {
                                                    getTimeAllowed(formik.values.initDate, HOURS_IN_ADVANCE).map((val, index) => (
                                                        <MenuItem key={index} value={val}>{val}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <p>{t('Check out')}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === 'es' ? es : enUS}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                className={classes.inputDate}
                                                margin={'none'}
                                                id="date-picker-inline-end"
                                                label=""
                                                minDate={formik.values.initDate}
                                                maxDate={moment(formik.values.initDate).add(HOURS_IN_ADVANCE, 'hours').toDate()}
                                                onChange={(name, value) => formik.setFieldValue('endDate', moment(value, 'DD/MM/yyyy').toDate())}
                                                value={formik.values.endDate}
                                                error={formik.touched.endDate && formik.errors.endDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                fullWidth
                                                helperText={''}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6} className='input-time'>
                                    <Grid container>
                                        <FormControl variant="standard" className={classes.formControl}>
                                            <InputLabel
                                                id="select-end-datetime" >{t('Hora')}</InputLabel>
                                            <Select
                                                labelId="select-end-datetime"
                                                id="demo-simple-select-outlined"
                                                onChange={(event, child) => formik.setFieldValue('endTime', event.target.value)}
                                                value={formik.values.endTime}
                                                error={formik.touched.endTime && formik.errors.endTime}
                                                label={t('Hora')}
                                                IconComponent={() => <AccessTime style={{ color: '#777777', marginRight: 12 }} />}
                                            >
                                                <MenuItem value="">
                                                    <em>{t('None')}</em>
                                                </MenuItem>
                                                {
                                                    getTimeAllowed(formik.values.endDate, MIN_HOURS_RESERVATION,
                                                        formik.values.initDate, formik.values.initTime,true).map((value, index) => (
                                                            <MenuItem key={index} value={value}>{value}</MenuItem>
                                                        ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={true}>
                            <Grid container>
                                <Button
                                    variant='contained'
                                    color="primary"
                                    size="large"
                                    type='submit'
                                    fullWidth>
                                    {t('Search Cabins')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                <Grid container item xs={12}>
                    <FormControl className='is-disabled'>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={formik.handleChange}
                                    checked={formik.values.isDisabled}
                                    name="isDisabled"
                                />
                            }
                            label={<span style={{ color: 'white' }}>{t('Reserve disabled')}</span>} 
                        />
                    </FormControl>
                </Grid>
            </form>
        </div>
    )
}
