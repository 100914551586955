import React, { useState } from 'react'
import VideoModal from './../modals/VideoModal'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import BackgroundImage from 'gatsby-background-image'
import { Container, Grid } from '@material-ui/core'

export default function HomeInfo() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const { placeholderImage } = useStaticQuery(
        graphql`
          query {
            placeholderImage: file(relativePath: { eq: "background-info.png" }) {
              childImageSharp {
                gatsbyImageData(quality:100,layout:FULL_WIDTH,transformOptions:{fit:COVER}, aspectRatio: 2.05 , placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        `
    )
    const image = getImage(placeholderImage)

    // Use like this:
    const bgImage = convertToBgImage(image)

    return (
        <>
            <BackgroundImage
                Tag="section"
                {...bgImage}
                alt='fondo info'
            >
                {/* <Video
                videoSrcURL="https://www.youtube.com/embed/xvM-kkBgrn4"
                videoTitle="Official Music Video on YouTube"
            /> */}
                <Container className='content-info'>
                    <Grid container >
                        <Grid item xs={12} md={4}>
                            <Grid container direction={'column'} justify={'flex-end'} alignItems={'flex-end'}>
                                <div
                                    data-sal="slide-right"
                                    data-sal-duration="1000"
                                    onClick={handleOpen} className={'pointer'}>
                                    <StaticImage
                                        src={'../../images/watch-video.png'}
                                        alt='watch video'
                                        quality={100}
                                        objectFit={'contain'}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <Grid container className='contenedor'>
                                {/* <Grid
                                    data-sal="slide-left"
                                    data-sal-duration="1000"
                                    data-sal-delay="300"
                                    container item xs={12} md={4} alignItems='center' justify='center'>
                                    <div className='item-info-container'>
                                        <div className='image'>
                                            <StaticImage
                                                src={'../../images/info-icon-1.png'}
                                                alt='watch video'
                                                quality={100}
                                                objectFit={'contain'}
                                            />
                                        </div>
                                        <p>{t('Home Info 1.1')}<br></br>{t('Home Info 1.2')}</p>
                                    </div>
                                </Grid> */}
                                <div class="grid-item" data-sal="slide-left" data-sal-duration="1000" data-sal-delay="300">
                                    <div class="grid-container">
                                        <div class="item-info-container">
                                            <div class="image">
                                                <StaticImage
                                                    src={'../../images/info-icon-1.png'}
                                                    alt='watch video'
                                                    quality={100}
                                                    objectFit={'contain'}
                                                />
                                            </div>
                                            <p>{t('Home Info 1.1')}<br></br>{t('Home Info 1.2')}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <Grid
                                    data-sal="slide-left"
                                    data-sal-duration="1000"
                                    data-sal-delay="500"
                                    item xs={12} md={4}>
                                    <Grid container alignItems='center' justify='center'>
                                        <div className='item-info-container'>
                                            <div className='image'>
                                                <StaticImage
                                                    src={'../../images/info-icon-2.png'}
                                                    alt='watch video'
                                                    quality={100}
                                                    objectFit={'contain'}
                                                />
                                            </div>
                                            <p>{t('Home Info 2.1')}<br></br>{t('Home Info 2.2')}</p>
                                        </div>
                                    </Grid>
                                </Grid> */}
                                <div class="grid-item" data-sal="slide-left" data-sal-duration="1000" data-sal-delay="500">
                                    <div class="grid-container">
                                        <div class="item-info-container">
                                            <div class="image">
                                                <StaticImage
                                                    src={'../../images/info-icon-2.png'}
                                                    alt='watch video'
                                                    quality={100}
                                                    objectFit={'contain'}
                                                />
                                            </div>
                                            <p>{t('Home Info 2.1')}<br></br>{t('Home Info 2.2')}</p>
                                        </div>
                                    </div>
                                </div>
                                {/* <Grid 
                                data-sal="slide-left"
                                data-sal-duration="1000"
                                data-sal-delay="700"
                                item xs={12} md={4}>
                                    <Grid container alignItems='center' justify='center'>
                                        <div className='item-info-container'>
                                            <div className='image'>
                                                <StaticImage
                                                    src={'../../images/info-icon-3.png'}
                                                    alt='watch video'
                                                    quality={100}
                                                    objectFit={'contain'}
                                                />
                                            </div>
                                            <p>{t('Home Info 3.1')}<br></br>{t('Home Info 3.2')}</p>
                                        </div>
                                    </Grid>
                                </Grid> */}
                                <div class="grid-item" data-sal="slide-left" data-sal-duration="1000" data-sal-delay="700">
                                    <div class="grid-container">
                                        <div class="item-info-container">
                                            <div class="image">
                                                <StaticImage
                                                    src={'../../images/info-icon-3.png'}
                                                    alt='watch video'
                                                    quality={100}
                                                    objectFit={'contain'}
                                                />
                                            </div>
                                            <p>{t('Home Info 3.1')}<br></br>{t('Home Info 3.2')}</p>

                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </BackgroundImage>
            {open && <VideoModal open={open} handleClose={handleClose} />}
        </>
    )
}
