import React from 'react'
import './VideoModal.scss'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Video from './../../Video'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', 
    },
    paper: {
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 10,
        width:'50vw',
        height:'calc(50vw*9/16+12)'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(-2),
        top: theme.spacing(-2),
        color: theme.palette.background.paper,
        backgroundColor : theme.palette.primary.main,
        '&:hover':{
            backgroundColor : theme.palette.primary.dark, 
        },
        zIndex:2
    },
}));

export default function VideoModal({ open = false, handleClose = () => { } }) {
    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="video waitnrest"
            aria-describedby="modal de video"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <Video
                        idVideo={'xvM-kkBgrn4'}
                        videoTitle="Official Music Video on YouTube"
                    />
                </div>
            </Fade>
        </Modal>
    )
}
