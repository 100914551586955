import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Container } from '@material-ui/core'
import IconPlane from '../../images/icon-plane.png'

export default function HomeSoonArriving() {
  const { t } = useTranslation();

  const { placeholderImage } = useStaticQuery(
    graphql`
          query {
            placeholderImage: file(relativePath: { eq: "background-soon-arriving.png" }) {
              childImageSharp {
                gatsbyImageData(quality:100,layout:FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
          }
        `
  )
  const image = getImage(placeholderImage)

  // Use like this:
  const bgImage = convertToBgImage(image)

  return (
    <BackgroundImage
      Tag="section"
      {...bgImage}
      preserveStackingContext
    >
      <Container

        className='home-soon-container' style={{ minHeight: 500, width: '100%' }}>
        <div
          data-sal="slide-left"
          data-sal-duration="2000">
          <img src={IconPlane} alt='plane' />
          <div>
            <p><span>{t('Soon Ttile P1')}</span><br></br><span>{t('Soon Ttile P2')}</span></p>
          </div>
        </div>
      </Container>
    </BackgroundImage>
  )
}
